<template>
    <b-row>
        <b-col lg="2" md="3" cols="4" class="text-right">
            <b-img-lazy :src="thumbnailSrc(commentaire.utilisateur, 'utilisateurs')"
                        :alt="textUsername(commentaire.utilisateur)" class="thumbnail-object-image"/>
        </b-col>
        <b-col cols="8">
            <h4>
                <user-name :user="commentaire.utilisateur"/>
                &middot; Le {{ formatDate(commentaire.date, 'Do MMMM YYYY [à] H[h]mm') }}
                <span v-if="canDeleteCommentaire(commentaire)" @click="deleteCommentaire"
                      class="text-danger cursor-pointer ml-2" v-b-tooltip.hover="'Supprimer'">
                    <font-awesome-icon :icon="['far', 'times-circle']"/>
                </span>
            </h4>
            <b-row>
                <b-col cols="12">
                    <div class="ck-content" v-html="sanitize(commentaire.texte)"></div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
    import {sanitize} from 'dompurify';

    import {textUsername}         from '@/util/text';
    import {formatDate}           from '@/util/date';
    import {thumbnailSrc}         from '@/util/image';
    import {canDeleteCommentaire} from '@/util/commentaire';
    import alert                  from '@/util/alert';
    import {apiPath}              from '@/util/http';

    const UserName = () => import('@/components/UserName');

    export default {
        name: "EvenementCommentaire",
        components: {UserName},
        props: {
            commentaire: {
                type: Object,
                required: true
            }
        },
        methods: {
            sanitize,
            formatDate,
            textUsername,
            thumbnailSrc,
            canDeleteCommentaire,
            deleteCommentaire() {
                alert.confirm(
                    'Supprimer ce commentaire ?',
                    () => {
                        alert.loading();
                        this.axios.delete(apiPath('delete_commentaire', {commentaire: this.commentaire.id}))
                            .then(() => {
                                this.$toaster.success('Commentaire supprimé');
                                this.$emit('delete');
                            })
                            .catch(() => this.$toaster.error('Impossible de supprimer le commentaire'))
                            .finally(alert.stopLoading);
                    }
                )
            }
        }
    }
</script>

<style scoped>
    .text-danger.cursor-pointer:hover {
        color: #9e0000 !important;
    }
</style>
